<template>
  <div class="container">
    <topHeader title="消费明细"></topHeader>
    <div>
      <div
        style="
          background: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 16px;
          height: 40px;
          align-items: center;
          color: #666666;
          font-size: 18px;
        "
      >
        <div
          style="display: flex; flex-direction: row; align-items: center"
          @click="handleChangeDate"
        >
          <span>{{ thisMonth }}</span>
          <span>月</span>
          <img
            style="height: 20px; width: 20px; margin-left: 4px"
            src="../../assets/images/bottomImg.png"
            alt=""
          />
        </div>
        <div
          style="padding: 5px 0px; box-sizing: border-box"
          @click="handleChangeStatus"
        >
          <div
            style="
              position: relative;
              border: 1px #f7f7f7 solid;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              height: 32px;
              line-height: 32px;
              padding: 0 10px;
              background: #f7f7f7;
              align-items: center;
              box-sizing: border-box;
            "
          >
            <div>{{ thisStatus }}</div>
            <img
              style="height: 20px; width: 20px; margin-left: 4px"
              src="../../assets/images/bottomImg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div style="flex: 1; overflow: auto">
      <van-list
        v-if="consumeList && consumeList.length > 0"
        v-model="loading"
        :finished="finished"
        @load="onLoad"
        style="background: #fff"
      >
        <ul
          v-if="consumeList && consumeList.length > 0"
          id="box"
          v-infinite-scroll="load"
          infinite-scroll-disabled="disabled"
          class="infinite-list"
          style="overflow: auto"
        >
          <li
            v-for="(item, index) in consumeList"
            id="box-item"
            :key="index"
            class="consume"
            @click="toDetail(item)"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0px 16px;
                color: #666666;
                height: 70px;
                font-size: 16px;
              "
            >
              <div>
                <div style="color: #333; margin-bottom: 10px">
                  {{ currentType(item.type) }}
                </div>
                <div style="font-size: 14px">{{ item.endTime }}</div>
              </div>
              <div>
                <div v-if="item.consumeMoney > 0">
                  +{{ getMoney(item.consumeMoney / 100) }}
                </div>
                <div v-if="item.consumeMoney <= 0">
                  {{ getMoney(item.consumeMoney / 100) }}
                </div>
              </div>
            </div>
            <CommonBorder></CommonBorder>
          </li>
        </ul>
      </van-list>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        "
        v-else
      >
        <img
          style="width: 280px; height: 150px; margin: 0px auto 20px"
          src="@/assets/images/noneImg.png"
          alt=""
        />
        <div style="text-align: center; color: #666666; font-size: 16px">
          暂无内容
        </div>
      </div>
    </div>
    <div>
      <van-popup v-model="show" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="handleSelectDate"
          @cancel="handleCancelDate"
        />
      </van-popup>

      <van-popup v-model="showStatus" position="bottom">
        <van-picker
          title
          show-toolbar
          :columns="constant.CONSUME_TYPE"
          :default-index="defaultIndex"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import { getDetail } from '@/api/api';
// import moment from 'moment'
import { timeToDate, getMoney } from '../../utils/util';
import topHeader from '@/components/topHeader';
import CommonBorder from '@/components/commonBorder';

export default {
  components: { topHeader, CommonBorder },
  name: 'Consumption',
  data() {
    return {
      cancel,
      consumeList: [],
      finished: false,
      loading: false,
      pagination: {
        current: 1,
        size: 10,
        total: 0
      },
      type: '',
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2099, 10, 1),
      currentDate: new Date(),
      show: false,
      thisMonth: '',
      startEndDate: '',
      startDate: '',
      endDate: '',
      thisStatus: '食堂',
      showStatus: false,
      status: 1,
      defaultIndex: 0
    };
  },
  mounted() {
    this.getMonth();
    this.detailOrder();
  },
  beforeRouteEnter(to, from, next) {
    console.log(from, 'beforeRouteEnter');
    next(async (vm) => {
      if (from.name !== 'ConsumptionDetail') {
        vm.consumeList = [];
        vm.thisStatus = '食堂';
        vm.status = 1;
        vm.defaultIndex = 0;
        vm.getMonth();
        await vm.detailOrder();
      }
    });
  },
  methods: {
    timeToDate,
    getMoney,
    toHome() {
      this.$router.back();
    },
    load() {
      this.consumeList.push(this.consumeList);
    },
    currentType(type) {
      switch (type) {
        case 1:
          return '食堂';
        case 2:
          return '餐券';
        case 3:
          return '伙食费';
        case 4:
          return '淋浴';
        default:
          return '';
      }
    },
    async detailOrder() {
      this.loading = true;
      const id = this.$store.getters.userId;
      const params = {
        id,
        ...this.pagination,
        type: this.status,
        startDate: this.startDate,
        endDate: this.endDate
      };
      const res = await getDetail(params);
      const { code, data } = res;
      if (code === 0) {
        if (this.pagination.current === 1) {
          this.consumeList = res.data.records;
        } else {
          res.data.records.map((item) => {
            this.consumeList.push(item);
          });
        }
        this.pagination = {
          current: data.current,
          size: data.size,
          total: data.total
        };
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    toDetail(item) {
      this.$router.push({
        name: 'ConsumptionDetail',
        query: { item: JSON.stringify(item), thisStatus: this.thisStatus }
      });
    },
    onLoad() {
      const {
        pagination: { current, size, total },
        detailOrder
      } = this;
      if (current * size >= total) {
        this.finished = true;
      } else {
        this.pagination = { current: current + 1, size, total };
        detailOrder();
      }
    },
    handleChangeDate() {
      this.show = true;
    },
    handleChangeStatus() {
      this.showStatus = true;
    },
    // 获取当前月份
    getMonth() {
      var currentDate = new Date();
      const year = new Date().getFullYear();
      this.thisMonth = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      this.startDate =
        this.getThisMonth(year, this.thisMonth).startDate + ' 00:00:00';
      this.endDate =
        this.getThisMonth(year, this.thisMonth).endDate + ' 23:59:59';
    },
    newDate(time) {
      var date = new Date(time);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      var day = date.getDate();
      day = day < 10 ? '0' + day : day;
      return year + '-' + month + '-' + day;
    },
    onConfirm(item, index) {
      this.thisStatus = item;
      this.showStatus = false;
      if (item === '食堂') {
        this.status = 1;
      } else if (item === '餐券') {
        this.status = 2;
      } else if (item === '伙食费') {
        this.status = 3;
      } else if (item === '淋浴') {
        this.status = 4;
      }
      this.defaultIndex = index;
      this.pagination.current = 1;
      this.detailOrder();
      this.finished = false;
    },
    onCancel() {
      this.pagination.current = 1;
      this.showStatus = false;
      this.finished = false;
      this.detailOrder();
    },
    handleCancelDate() {
      this.pagination.current = 1;
      this.detailOrder();
      this.show = false;
    },
    handleSelectDate(val) {
      this.startEndDate = this.getThisMonth(
        this.newDate(val).split('-')[0],
        this.newDate(val).split('-')[1]
      );
      this.startDate = this.startEndDate.startDate + ' 00:00:00';
      this.endDate = this.startEndDate.endDate + ' 23:59:59';
      this.thisMonth = this.startEndDate.startDate.slice(5, 7);
      this.show = false;
      this.pagination.current = 1;
      this.detailOrder();
      this.finished = false;
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    // 获取当月（第一天和最后一天）
    getThisMonth(year, month) {
      month = month - 1;
      const startDate = new Date(year, month, 1);
      const endDate = new Date(year, month + 1, 0);
      return {
        startDate: this.newDate(startDate),
        endDate: this.newDate(endDate)
      };
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .search {
    display: flex;
    height: 40px;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    padding: 0 20px;
    .nav-content {
      background: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 40px;
      height: 40px;
    }
  }
  .header {
    width: 100%;
    background: #fff;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid #ccc;
    .header-title {
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }

  .content {
    // width:100%;
    flex: 1;
    background: #f7f7f7;
    // padding: 15px;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 20px;
    .content-items {
      background: #fff;
      box-sizing: border-box;
      color: #333;
      padding: 0px 15px;
      overflow: auto;
      height: 100%;

      .item-margin {
        overflow: scroll;
        margin-top: 0.5rem;
        background-color: #fff;
        .consume {
          background-color: #fff;
          width: 95%;
          margin: 0 auto;
          border-bottom: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          color: rgba(85, 85, 85);
          padding: 10px 0;
          .left {
            width: 50%;
            .top {
              font-size: 1.1rem;
              font-weight: 600;
              line-height: 30px;
              color: #333;
            }
            .bottom {
              font-size: 0.8rem;
              line-height: 24px;
              color: rgba(176, 176, 176);
            }
          }
          .right {
            width: 50%;
            text-align: right;
          }
        }
      }
    }
  }
}

::v-deep .van-picker__toolbar {
  height: 50px;
  background-color: #f1f1f1;
  .van-picker__cancel {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
  .van-picker__confirm {
    font-size: 16px;
    font-weight: 500;
    color: #fa5050;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
}
::v-deep .van-picker-column__item {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #333333;
  font-family: 'PingFangSC-Regular, sans-serif';
}
</style>
